import { apiRequest } from "../../api/apiRequest";
import { WORKSPACE_ENDPOINT, USER_ENDPOINT } from "../../api/urls";

export const fetchWorkspaces = () => {
    return apiRequest("GET", `${USER_ENDPOINT}workspaces/`);
};

export const addWorkspace = (data) => {
    return apiRequest("POST", WORKSPACE_ENDPOINT + "create/", data);
};

export const updateWorkspace = (id, data) => {
    return apiRequest("PATCH", `${WORKSPACE_ENDPOINT}${id}/record/`, data);
};

export const fetchWorkspacePermissions = (id) => {
    return apiRequest("GET", `${WORKSPACE_ENDPOINT}${id}/permissions/`);
};

export const getWorkspace = (id) => {
    return apiRequest("GET", `${WORKSPACE_ENDPOINT}${id}/record/`);
};

export const deleteWorkspace = (id) => {
    return apiRequest("DELETE", WORKSPACE_ENDPOINT + id);
};
