import { apiRequest } from "../../api/apiRequest";
import { WORKSPACE_ENDPOINT } from "../../api/urls";

export const fetchMembers = (id) => {
    return apiRequest("GET", WORKSPACE_ENDPOINT + "" + id + "/members/");
};

export const updateMembership = (id, workspace_id, data) => {
    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/update/member/${id}/`,
        data,
    );
};

export const updateUserRole = (id, workspace_id, data) => {
    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/edit/member/${id}/role/`,
        data,
    );
};
