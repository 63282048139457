import { lazy } from "react";

const retry = (fn, retriesLeft = 5, interval = 1000) => {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        reject(error);
                        return;
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
};

// code splitting...
const AcceptInvitationContainerPromise = import(
    "../../containers/AcceptInvitationPage"
);
export const AcceptInvitationContainer = lazy(() =>
    retry(() => AcceptInvitationContainerPromise),
);

const WelcomeContainerPromise = import("../../containers/WelcomePage");
export const WelcomeContainer = lazy(() =>
    retry(() => WelcomeContainerPromise),
);

const CompleteProfileContainerPromise = import(
    "../../containers/CompleteProfilePage"
);
export const CompleteProfileContainer = lazy(() =>
    retry(() => CompleteProfileContainerPromise),
);

const LoginContainerPromise = import("../../containers/LoginPage");
export const LoginContainer = lazy(() => retry(() => LoginContainerPromise));

const SignUpContainerPromise = import("../../containers/SignUpPage");
export const SignUpContainer = lazy(() => retry(() => SignUpContainerPromise));

const WorkspaceContainerPromise = import("../../containers/Workspace");
export const WorkspaceContainer = lazy(() =>
    retry(() => WorkspaceContainerPromise),
);

const WorkspaceFilesContainerPromise = import("../../containers/FilesPage");
export const WorkspaceFilesContainer = lazy(() =>
    retry(() => WorkspaceFilesContainerPromise),
);

const MembersContainerPromise = import("../../containers/Members");
export const MembersContainer = lazy(() =>
    retry(() => MembersContainerPromise),
);

const InvitationsContainerPromise = import("../../containers/Invitations");
export const InvitationsContainer = lazy(() =>
    retry(() => InvitationsContainerPromise),
);

const HomePageContainerPromise = import("../../containers/HomePage");
export const HomePageContainer = lazy(() =>
    retry(() => HomePageContainerPromise),
);

const FileDetailsContainerPromise = import("../../containers/FileDetails");
export const FileDetailsContainer = lazy(() =>
    retry(() => FileDetailsContainerPromise),
);

const SettingsContainerPromise = import("../../containers/SettingsPage");
export const SettingsContainer = lazy(() =>
    retry(() => SettingsContainerPromise),
);
