import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CustomListItem } from "./ListItem";

import LogoIcon from "../../assets/logo.png";
import InvitationIcon from "../../assets/invitation.svg";
import ManageHomeIcon from "../../assets/manage_home.svg";
import ManageUserIcon from "../../assets/manage_user.svg";
import MemberIcon from "../../assets/member.svg";
import { ListItem, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const drawerWidth = 190;

const useStyles = makeStyles(() => ({
    icon: {
        width: "10px",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
        },
        zIndex: 1300,
    },
    drawerPaperProps: {
        overflow: "auto",
        backgroundColor: "white",
        color: "black",
        marginTop: "10px",
    },
    logoContainer: {
        marginTop: "6px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    orgDetailsContainer: {
        position: "fixed",
        bottom: 0,
    },
}));

export const ImgIcon = ({ src, width = "40px" }) => (
    <img src={src} style={{ width: width }} />
);

const manageMenus = [
    {
        text: "Submissions",
        icon: <ImgIcon src={ManageHomeIcon} />,
        path: "/my-files",
        menuId: 0,
        permission: { action: "view", view: "workspace" },
    },
    {
        text: "Organisations",
        icon: <ImgIcon src={ManageUserIcon} />,
        path: "/my-workspaces",
        menuId: 1,
        permission: { action: "view", view: "members" },
    },
    {
        text: "Invitations",
        icon: <ImgIcon src={InvitationIcon} />,
        path: "/invitations",
        menuId: 2,
        permission: { action: "view", view: "invitations" },
    },
    {
        text: "Members",
        icon: <ImgIcon src={MemberIcon} />,
        path: "/team_members",
        menuId: 3,
        permission: { action: "view", view: "members" },
    },
    {
        text: "Settings",
        icon: <ImgIcon src={MemberIcon} />,
        path: "/settings",
        menuId: 4,
        permission: { action: "upload", view: "template" },
    },
];

const DEFAULT_MENU_ID = 0;

export const SideBar = () => {
    const history = useHistory();
    const classes = useStyles();

    const [selectedMenuId, selectMenu] = useState(DEFAULT_MENU_ID);

    const authStates = useSelector((state) => state.auth);
    const { user } = authStates?.fetchUserState;

    const workspaceStates = useSelector((state) => {
        return state.workspaces;
    });
    const { active_workspace } = workspaceStates?.logintoWorkspaceState;
    const { role } = workspaceStates?.fetchWorkspacePermissionsState;

    return (
        <Drawer
            variant="permanent"
            className={classes.drawer}
            PaperProps={{
                sx: {
                    backgroundColor: "white",
                    paddingLeft: "0px",
                    fontSize: "12px",
                },
            }}
        >
            <Toolbar>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                >
                    <Box component="div" className={classes?.logoContainer}>
                        <ImgIcon src={LogoIcon} width="90%" />
                    </Box>
                </Stack>
            </Toolbar>
            <Box className={classes.drawerPaperProps}>
                <List>
                    {manageMenus.map((item) => (
                        <CustomListItem
                            key={item?.text}
                            item={item}
                            selectedMenuId={selectedMenuId}
                            listItemAction={() => {
                                if (item?.path === "/help")
                                    window?.open("/", "_blank");

                                selectMenu(item?.menuId);

                                history.push(item?.path);
                            }}
                        />
                    ))}
                </List>
            </Box>
            <Box className={classes.drawerPaperProps}>
                <List className={classes.orgDetailsContainer}>
                    <ListItem button>
                        <Stack
                            direction={"column"}
                            sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                width: "160px",
                                overflow: "hidden",
                            }}
                        >
                            <Typography typography={"title6"}>
                                {user?.user?.email}
                            </Typography>
                            <Typography typography={"title6"}>
                                Role: {role}
                            </Typography>
                            <Typography typography={"title6"}>
                                {active_workspace?.name}
                            </Typography>
                        </Stack>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
};
