import { apiRequest } from "../../api/apiRequest";
import { WORKSPACE_ENDPOINT } from "../../api/urls";

export const downloadTemplate = (workspace_id) => {
    return apiRequest(
        "GET",
        `${WORKSPACE_ENDPOINT}${workspace_id}/download/template/`,
        {},
        "blob",
    );
};

export const uploadFile = (workspace_id, data, uploadProgress) => {
    const formData = new FormData();
    formData.append("file_url", data);

    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/upload/file/`,
        formData,
        "json",
        { "Content-Type": "multipart/form-data" },
        uploadProgress,
    );
};

export const fetchWorkspaceFiles = (
    workspace_id,
    searchTerm = "",
    status = "",
) => {
    return apiRequest(
        "GET",
        `${WORKSPACE_ENDPOINT}${workspace_id}/get_files/?searchTerm=${searchTerm}&&status=${status}`,
    );
};

export const fetchFileDetails = (file_id) => {
    return apiRequest(
        "GET",
        `${WORKSPACE_ENDPOINT}file/${file_id}/`,
        {},
        "blob",
    );
};

export const updateFileStatus = (file_id, data) => {
    return apiRequest(
        "PATCH",
        `${WORKSPACE_ENDPOINT}file/${file_id}/change_status/`,
        data,
    );
};

export const reUploadFile = (file_id, data, uploadProgress) => {
    const formData = new FormData();
    formData.append("file_url", data);

    return apiRequest(
        "PATCH",
        `${WORKSPACE_ENDPOINT}file/${file_id}/re_upload_file/`,
        formData,
        "json",
        { "Content-Type": "multipart/form-data" },
        uploadProgress,
    );
};

export const uploadFileTemplate = (workspace_id, data, uploadProgress) => {
    const formData = new FormData();
    formData.append("file_url", data);

    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/upload/template/`,
        formData,
        "json",
        { "Content-Type": "multipart/form-data" },
        uploadProgress,
    );
};

export const getFileHistory = (file_id) => {
    return apiRequest("GET", `${WORKSPACE_ENDPOINT}file/${file_id}/history/`);
};

// export const getFileStats = (workspace_id, status = "", searchTerm = "") => {
//     return apiRequest(
//         "GET",
//         `${WORKSPACE_ENDPOINT}${workspace_id}/files/statistics/?searchTerm=${searchTerm}&&status=${status}`,
//     );
// };

export const getFileStats = (workspace_id) => {
    return apiRequest(
        "GET",
        `${WORKSPACE_ENDPOINT}${workspace_id}/files/statistics/`,
    );
};
