import { axiosInstance } from "./axiosDefaults";

export const apiRequest = (
    method,
    url,
    data = {},
    responseType = "json",
    extra_headers = {},
    onUploadProgress,
    onDownloadProgress,
) => {
    const response = axiosInstance(extra_headers)({
        method,
        url,
        data,
        responseType,
        onUploadProgress,
        onDownloadProgress,
    });

    return response;
};
