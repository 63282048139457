import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../containers/NotFound";
import {
    WelcomeContainer,
    LoginContainer,
    SignUpContainer,
    WorkspaceContainer,
    HomePageContainer,
    MembersContainer,
    WorkspaceFilesContainer,
    InvitationsContainer,
    AcceptInvitationContainer,
    SettingsContainer,
    FileDetailsContainer,
    CompleteProfileContainer,
} from "../util/helpers/lazyLoader";

export const AuthenticatedRoutes = () => {
    return (
        <Switch>
            <Route path="/" exact component={AcceptInvitationContainer} />
            <Route
                path="/complete-profile"
                exact
                component={CompleteProfileContainer}
            />
            <Route path="/welcome" exact component={WelcomeContainer} />
            <Route path="/workspace" exact component={WorkspaceContainer} />
            <Route path="/my-workspaces" exact component={HomePageContainer} />
            <Route path="/my-files" exact component={WorkspaceFilesContainer} />
            <Route
                path="/file/:fileId/details"
                exact
                component={FileDetailsContainer}
            />
            <Route path="/team_members" exact component={MembersContainer} />
            <Route path="/invitations" exact component={InvitationsContainer} />
            <Route path="/settings" exact component={SettingsContainer} />
            <Route component={() => <NotFound redirect_pathname="/" />} />
        </Switch>
    );
};

export const UnauthenticatedRoutes = ({ isLoggedIn }) => {
    return (
        <Switch>
            <Route path="/" exact component={LoginContainer} />
            <Route path="/sign-up" exact component={SignUpContainer} />
            <Route component={() => <NotFound redirect_pathname="/" />} />
        </Switch>
    );
};
