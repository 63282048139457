import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateMembershipState: { membership: null, isloading: "", errors: null },
    updateUserRoleState: { role: null, isloading: "", errors: null },
    fetchMembersState: { members: [], isloading: "", errors: null },
};

export const membershipSlice = createSlice({
    name: "membership",
    initialState,
    reducers: {
        updateMembership: (state) => {
            state.updateMembershipState.isloading = "processing";
            state.updateMembershipState.errors = null;
        },
        updateMembershipSuccess: (state, action) => {
            state.updateMembershipState.isloading = "success";
            state.updateMembershipState.membership = action?.payload;
        },
        updateMembershipFailed: (state, action) => {
            state.updateMembershipState.isloading = "failed";
            state.updateMembershipState.errors = action?.payload;
        },
        updateUserRole: (state) => {
            state.updateUserRoleState.isloading = "processing";
            state.updateUserRoleState.errors = null;
        },
        updateUserRoleSuccess: (state, action) => {
            state.updateUserRoleState.isloading = "success";
            state.updateUserRoleState.role = action?.payload;
        },
        updateUserRoleFailed: (state, action) => {
            state.updateUserRoleState.isloading = "failed";
            state.updateUserRoleState.errors = action?.payload;
        },
        fetchMembers: (state) => {
            state.fetchMembersState.isloading = "processing";
            state.fetchMembersState.members = null;
            state.fetchMembersState.errors = null;
        },
        fetchMembersSuccess: (state, action) => {
            state.fetchMembersState.isloading = "success";
            state.fetchMembersState.members = action?.payload;
        },
        fetchMembersFailed: (state, action) => {
            state.fetchMembersState.isloading = "failed";
            state.fetchMembersState.errors = action?.payload;
        },
        resetMembership: (state) => {
            state.fetchMembersState.isloading = "";
            state.fetchMembersState.members = null;
            state.fetchMembersState.errors = null;
        },
        resetUserRole: (state) => {
            state.updateUserRoleState.isloading = "";
            state.updateUserRoleState.members = null;
            state.updateUserRoleState.errors = null;
        },
    },
});

export const {
    updateMembership,
    updateMembershipSuccess,
    updateMembershipFailed,
    updateUserRole,
    updateUserRoleSuccess,
    updateUserRoleFailed,
    fetchMembers,
    fetchMembersSuccess,
    fetchMembersFailed,
    resetMembership,
    resetUserRole,
} = membershipSlice.actions;

export default membershipSlice.reducer;
