import authSlice from "./services/common/auth/slice";
import invitationSlice from "./services/common/invitations/slice";
import membershipSlice from "./services/common/membership/slice";
import filesSlice from "./services/common/files/slice";
import workspaceSlice from "./services/common/workspace/slice";

const rootReducer = {
    auth: authSlice,
    workspaces: workspaceSlice,
    invitation: invitationSlice,
    membership: membershipSlice,
    files: filesSlice,
};

export default rootReducer;
