import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import clsx from "clsx";

import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core";

import { StyledButton } from "../../StyledButton";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Can } from "../../../util/helpers/Can";
import { useDispatch } from "react-redux";
import {
    fetchWorkspacePermissions,
    logintoWorkspace,
} from "../../../services/common/workspace/slice";

const useStyles = makeStyles({
    icon: {
        color: "#fff",
        backgroundColor: "#54ADEC",
    },
    menu: {
        fontSize: "15px",
        width: "500px",
    },
    menuDiv: {
        width: "100% !important",
        padding: "20px",
    },
    workspaceSection: {
        padding: "0 !important",
    },
    workspaceWrapper: {
        padding: "0 0 15px !important",
        maxHeight: "200px !important",
        overflow: "scroll",
    },
    createWorkspaceSection: {
        padding: "10px 0",
    },
    logoutSection: {
        padding: "20px 0 0 !important",
        display: "flex",
        justifyContent: "center",
    },
    title: {
        fontSize: "18px !important",
        fontWeight: 500,
    },
    linkText: {
        color: "#2173AD !important",
    },
    underlinedText: {
        textDecoration: "underline",
    },
    createWorkspaceLink: {
        padding: "10px 15px !important",
    },
    profileDiv: {
        padding: "5px 0",
    },
    profileId: {
        fontSize: "10px",
    },
    menuListProps: {
        width: 300,
    },
    paperProps: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        width: 300,
    },
});

const StyledMenuItem = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
})(MenuItem);

const ProfileMenu = ({ user, active_workspace, workspaces, logout }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const MenuBoxContainer = ({ children }) => (
        <Box
            component="div"
            className={clsx(classes.menuDiv, classes.workspaceSection)}
        >
            {children}
        </Box>
    );

    return (
        <>
            <IconButton
                aria-label="more"
                id="icon-button"
                aria-controls={open ? "profile-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Avatar className={classes.icon} />
            </IconButton>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "icon-button",
                    className: classes.menuListProps,
                }}
                PaperProps={{
                    elevation: 0,
                    className: classes.paperProps,
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                className={classes.menu}
            >
                <MenuList dense>
                    <StyledMenuItem sx={{ cursor: "default" }}>
                        <Box component="div" className={classes.profileDiv}>
                            <Typography typography={"title5"}>
                                Profile ID:
                            </Typography>
                            <Typography className={classes.profileId}>
                                {user?.email || user?.username}
                            </Typography>
                        </Box>
                    </StyledMenuItem>
                    <Divider />
                    {active_workspace && (
                        <>
                            <StyledMenuItem sx={{ cursor: "default" }}>
                                <MenuBoxContainer>
                                    <Typography typography={"title6"}>
                                        Organisations (click to switch)
                                    </Typography>
                                </MenuBoxContainer>
                            </StyledMenuItem>

                            <MenuBoxContainer>
                                <Box className={clsx(classes.workspaceWrapper)}>
                                    {workspaces
                                        ?.filter(
                                            (workspace) =>
                                                workspace?.id !==
                                                active_workspace?.id,
                                        )
                                        ?.map((workspace) => (
                                            <Can
                                                I="view"
                                                a="workspaces"
                                                key={workspace?.id}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        dispatch(
                                                            logintoWorkspace(
                                                                workspace,
                                                            ),
                                                        );
                                                        dispatch(
                                                            fetchWorkspacePermissions(
                                                                {
                                                                    id: workspace?.slug,
                                                                },
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    <ListItemText>
                                                        <Typography
                                                            component="div"
                                                            className={clsx(
                                                                classes.linkText,
                                                                classes.underlinedText,
                                                            )}
                                                            typography={
                                                                "title6"
                                                            }
                                                        >
                                                            {workspace?.name}
                                                        </Typography>
                                                    </ListItemText>
                                                </MenuItem>{" "}
                                            </Can>
                                        ))}
                                </Box>
                            </MenuBoxContainer>
                        </>
                    )}
                    <Divider />
                    <MenuBoxContainer>
                        <MenuItem
                            className={classes.createWorkspaceLink}
                            onClick={() => history.push("/workspace")}
                        >
                            <Can I="create" a="workspace">
                                <Typography
                                    component="div"
                                    className={clsx(classes.linkText)}
                                    typography={"title6"}
                                >
                                    Create a new organisation
                                </Typography>
                            </Can>
                        </MenuItem>
                    </MenuBoxContainer>
                    <Divider />
                    <StyledMenuItem>
                        <Box
                            component="div"
                            className={clsx(
                                classes.menuDiv,
                                classes.logoutSection,
                            )}
                        >
                            <StyledButton
                                variant="contained"
                                disabledelevation="true"
                                onClick={logout}
                            >
                                Logout
                            </StyledButton>
                        </Box>
                    </StyledMenuItem>
                </MenuList>
            </Menu>
        </>
    );
};

ProfileMenu.propTypes = {
    user: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ]),
    active_workspace: PropTypes.object,
    workspaces: PropTypes.array,
    logout: PropTypes.func.isRequired,
};

export { ProfileMenu };
