import { apiRequest } from "../../api/apiRequest";
import { AUTH_ENDPOINT, USER_ENDPOINT } from "../../api/urls";

export const login = (data) => {
    return apiRequest("POST", AUTH_ENDPOINT + "login/", data);
};

export const signup = (data) => {
    return apiRequest("POST", AUTH_ENDPOINT + "registration/", data);
};

export const updateProfile = (data) => {
    return apiRequest("PATCH", AUTH_ENDPOINT + "user/", data);
};

export const logout = () => {
    return apiRequest("POST", AUTH_ENDPOINT + "logout/");
};

export const fetchUser = () => {
    return apiRequest("GET", USER_ENDPOINT + "current/");
};

export const fetchAnonymousUserInvitation = () => {
    return apiRequest("GET", USER_ENDPOINT + "anonymous/invitation/");
};
