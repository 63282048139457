import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Can } from "../../util/helpers/Can";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
    icon: {
        color: "#fff",
    },
}));

const CustomListItem = ({ item, listItemAction, selectedMenuId }) => {
    const classes = useStyles();

    return (
        <Can I={item?.permission?.action} a={item?.permission?.view}>
            <ListItem
                button
                onClick={listItemAction}
                sx={{
                    // background: item?.menuId === selectedMenuId ? "#969696" : "inherit",
                    color:
                        item?.menuId === selectedMenuId ? "#969696" : "inherit",
                }}
            >
                {/* <ListItemIcon className={classes.icon}>
                    {item?.icon}
                </ListItemIcon> */}
                <ListItemText>
                    <Typography typography={"menu3"}>{item?.text}</Typography>
                </ListItemText>
            </ListItem>
        </Can>
    );
};

CustomListItem.propTypes = {
    item: PropTypes.object.isRequired,
    listItemAction: PropTypes.func.isRequired,
    selectedMenuId: PropTypes.number,
};

export { CustomListItem };
