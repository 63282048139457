import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ProfileMenu } from "./components/ProfileMenu";
import { logoutRequest } from "../../services/common/auth/slice";
import {
    NotificationsActive,
    NotificationsOutlined,
    PowerOffOutlined,
    PowerOutlined,
    PowerSettingsNew,
} from "@mui/icons-material";
import { ImgIcon } from "../Sidebar";
import LogoIcon from "../../assets/logo.png";

const useStyles = makeStyles((theme) => ({
    logo: {
        height: "40px",
        paddingTop: "2px",
        marginTop: "8px",
    },
    logoWrapper: {
        flexGrow: 1,
        color: "#6590B9",
    },
    appBar: {
        background: "white",
        color: "black",
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "rgba(204, 204, 204, 0.5) 0px 2px 4px 0px",
    },
}));

export const PageHeader = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const authStates = useSelector((state) => state.auth);
    const { user } = authStates?.fetchUserState;

    const workspaceStates = useSelector((state) => {
        return state.workspaces;
    });
    const { active_workspace } = workspaceStates?.logintoWorkspaceState;
    const { workspaces } = workspaceStates?.fetchWorkspacesState;

    useEffect(() => {
        if (user) {
            if (user?.user?.is_admin) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
            setIsLoggedIn(true);
        } else setIsLoggedIn(false);
    }, [user]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Box component="div" className={classes.logoWrapper}>
                            <Stack direction="row" alignItems="center">
                                <Box sx={{ width: "190px", marginLeft: "7px" }}>
                                    <ImgIcon src={LogoIcon} width="90%" />
                                </Box>
                                <Typography
                                    typography={"title2"}
                                    // sx={{ marginLeft: "200px" }}
                                    sx={{ marginLeft: "5px" }}
                                >
                                    TIMS DATA CAPTURE SYSTEM
                                </Typography>
                            </Stack>
                        </Box>
                        {isLoggedIn && isAdmin && (
                            <>
                                <Typography typography={"title4"}>
                                    LoggedIn: {active_workspace?.name}
                                </Typography>
                                <ProfileMenu
                                    user={user?.user}
                                    active_workspace={active_workspace}
                                    workspaces={workspaces}
                                    logout={() => {
                                        dispatch(logoutRequest());
                                    }}
                                />
                            </>
                        )}
                        {!isAdmin && isLoggedIn && (
                            <Stack
                                direction="row"
                                alignItems={"center"}
                                sx={{ marginRight: "15px" }}
                            >
                                <Typography typography={"title4"}>
                                    {user?.user?.last_name}{" "}
                                    {user?.user?.first_name}
                                </Typography>
                                <NotificationsActive
                                    sx={{ marginLeft: "8px" }}
                                />
                                <PowerSettingsNew
                                    sx={{
                                        marginLeft: "8px",
                                        fontSize: "35px",
                                        color: "green",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => dispatch(logoutRequest())}
                                />
                            </Stack>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
};
