import * as yup from "yup";

export const sortItems = (items, keyToSort) => {
    return [...items].sort((a, b) => {
        if (a[keyToSort] < b[keyToSort]) {
            return -1;
        }
        if (a[keyToSort] > b[keyToSort]) {
            return 1;
        }
        return 0;
    });
};

export const validationWorkspaceFormSchema = yup.object({
    name: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
});

export const validationLoginFormSchema = yup.object({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
});

export const validationSignUpFormSchema = yup.object({
    username: yup.string().required("Username is required"),
    email: yup.string().required("Email is required"),
    password1: yup.string().required("Password is required"),
    password2: yup
        .string()
        .oneOf([yup.ref("password1"), null], "Passwords must match"),
});

export const validationCompleteProfileFormSchema = yup.object({
    last_name: yup.string().required("Last name is required"),
    first_name: yup.string().required("First name is required"),
});

export const openPage = (url) => {
    if (url) window.location.replace(url, "blank_");
};

export const validateEmails = (value) => {
    var validRegExp =
        /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[_a-zA-Z0-9-]+)?@[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\.[A-Za-z]+)$/;
    let emails = [];
    if (value) emails = value.split(",");
    if (emails.length > 0) {
        let result = true;
        emails.forEach((v) => {
            let trimmedEmail = v.trim();
            if (!validRegExp.test(trimmedEmail)) {
                result = false;
            }
        });
        return result;
    } else {
        if (!validRegExp.test(value)) {
            return false;
        }
    }
    return true;
};

export const processResponseObject = (response) => {
    try {
        if (response && response.detail) {
            return response.detail;
        }

        let errorsArray = [];

        for (const [key, value] of Object.entries(response)) {
            if (key) {
                let _val = "";
                if (value && Array.isArray(value) && value.length > 0) {
                    value.forEach((message) => {
                        _val += message;
                    });
                } else {
                    _val = value;
                }
                errorsArray.push(_val);
            }
        }

        return errorsArray;
    } catch (err) {
        return null;
    }
};

export const extractErrors = (response) => {
    try {
        if (Object.keys(response)) {
            if (Object.keys(response)[0] === "non_field_errors") {
                return response["non_field_errors"][0];
            }
        }
    } catch (err) {
        return null;
    }

    return null;
};
