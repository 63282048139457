import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loginState: { isloading: "", errors: null },
    signUpState: { isloading: "", errors: null },
    updateProfileState: { isloading: "", errors: null },
    loginRedirectState: { message: null },
    fetchUserState: { user: null, isloading: "", errors: null },
    fetchAnonymousUserInvitationState: {
        anonymous: null,
        isloading: "",
        errors: null,
    },
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        fetchUser: (state) => {
            state.fetchUserState.isloading = "processing";
            state.fetchUserState.errors = null;
        },
        fetchUserSuccess: (state, action) => {
            state.fetchUserState.isloading = "success";
            state.fetchUserState.user = action?.payload;
        },
        fetchUserFailed: (state, action) => {
            state.fetchUserState.isloading = "failed";
            state.fetchUserState.errors = action?.payload;
        },
        fetchAnonymousUserInvitation: (state) => {
            state.fetchAnonymousUserInvitationState.isloading = "processing";
            state.fetchAnonymousUserInvitationState.errors = null;
        },
        fetchAnonymousUserInvitationSuccess: (state, action) => {
            state.fetchAnonymousUserInvitationState.isloading = "success";
            state.fetchAnonymousUserInvitationState.anonymous = action?.payload;
        },
        fetchAnonymousUserInvitationFailed: (state, action) => {
            state.fetchAnonymousUserInvitationState.isloading = "failed";
            state.fetchAnonymousUserInvitationState.errors = action?.payload;
        },
        loginRequest: (state) => {
            state.loginState.isloading = "processing";
            state.loginState.errors = null;
            state.loginRedirectState.message = null;
        },
        loginSuccess: (state) => {
            state.loginState.isloading = "success";
        },
        loginFailed: (state, action) => {
            state.loginState.isloading = "failed";
            state.loginState.errors = action?.payload;
        },
        signUpRequest: (state) => {
            state.signUpState.isloading = "processing";
            state.signUpState.errors = null;
        },
        signUpSuccess: (state) => {
            state.signUpState.isloading = "success";
        },
        signUpFailed: (state, action) => {
            state.signUpState.isloading = "failed";
            state.signUpState.errors = action?.payload;
        },
        updateProfile: (state) => {
            state.updateProfileState.isloading = "processing";
            state.updateProfileState.errors = null;
        },
        updateProfileSuccess: (state) => {
            state.updateProfileState.isloading = "success";
        },
        updateProfileFailed: (state, action) => {
            state.updateProfileState.isloading = "failed";
            state.updateProfileState.errors = action?.payload;
        },
        logoutRequest: (state) => {
            state.fetchUserState.isloading = "";
            state.fetchUserState.user = null;
        },
        loginRedirect: (state, actions) => {
            state.loginRedirectState.message = actions?.payload;
        },
        resetLogin: (state) => {
            state.loginState.isloading = "";
            state.loginState.errors = null;
            state.loginRedirectState.message = null;
        },
    },
});

export const {
    loginRequest,
    loginSuccess,
    loginFailed,
    signUpRequest,
    signUpSuccess,
    signUpFailed,
    updateProfile,
    updateProfileSuccess,
    updateProfileFailed,
    logoutRequest,
    loginRedirect,
    fetchUser,
    fetchUserSuccess,
    fetchUserFailed,
    fetchAnonymousUserInvitation,
    fetchAnonymousUserInvitationSuccess,
    fetchAnonymousUserInvitationFailed,
    resetLogin,
} = authSlice.actions;

export default authSlice.reducer;
