import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { extractErrors, processResponseObject } from "../../../util/helpers";
import {
    downloadTemplate as downloadTemplateApi,
    uploadFile as uploadFileApi,
    fetchWorkspaceFiles as fetchWorkspaceFilesApi,
    fetchFileDetails as fetchFileDetailsApi,
    updateFileStatus as updateFileStatusApi,
    reUploadFile as reUploadFileApi,
    uploadFileTemplate as uploadFileTemplateApi,
    getFileHistory as getFileHistoryApi,
    getFileStats as getFileStatsApi,
} from "./api";
import {
    downloadTemplate,
    downloadTemplateSuccess,
    downloadTemplateFailed,
    uploadFile,
    uploadFileSuccess,
    uploadFileFailed,
    fetchWorkspaceFiles,
    fetchWorkspaceFilesSuccess,
    fetchWorkspaceFilesFailed,
    fetchFileDetails,
    fetchFileDetailsSuccess,
    fetchFileDetailsFailed,
    updateFileStatus,
    updateFileStatusSuccess,
    updateFileStatusFailed,
    fetchFileHistory,
    fetchFileHistorySuccess,
    fetchFileHistoryFailed,
    fetchFileStats,
    fetchFileStatsSuccess,
    fetchFileStatsFailed,
    reUploadFile,
    reUploadFileSuccess,
    reUploadFileFailed,
    uploadFileTemplate,
    uploadFileTemplateSuccess,
    uploadFileTemplateFailed,
} from "./slice";

export function* getTemplate(actions) {
    try {
        const { id } = actions.payload;
        const response = yield call(downloadTemplateApi, id);

        const url = URL.createObjectURL(new Blob([response.data]), {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute(
            "download",
            response.headers["content-disposition"].split("filename=")[1],
        );
        aTag.click();
        aTag.remove();
        URL.revokeObjectURL(url);

        yield put(downloadTemplateSuccess({ status: 200 }));
    } catch (e) {
        const message =
            "The template for this workspace does not exist, please upload one via the settings or contact your admin to do so";
        yield put(downloadTemplateFailed(message));
    }
}

export function* sendFile(actions) {
    try {
        const { id, data, uploadProgress } = actions.payload;

        const response = yield call(uploadFileApi, id, data, uploadProgress);
        const resp = response && response.data;

        yield put(uploadFileSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to upload file";
        yield put(uploadFileFailed(message));
    }
}

export function* getFiles(actions) {
    try {
        const { workspace_id, searchTerm, status } = actions.payload;

        const response = yield call(
            fetchWorkspaceFilesApi,
            workspace_id,
            searchTerm,
            status,
        );
        const resp = response && response.data;

        yield put(fetchWorkspaceFilesSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to get workspace files";
        yield put(fetchWorkspaceFilesFailed(message));
    }
}

export function* getFileDetails(actions) {
    try {
        const { file_id } = actions.payload;

        const response = yield call(fetchFileDetailsApi, file_id);
        // const resp = response && response.data;

        yield put(fetchFileDetailsSuccess(response));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to get file details";
        yield put(fetchFileDetailsFailed(message));
    }
}

export function* changeFileStatus(actions) {
    try {
        const { file_id, data } = actions.payload;

        const response = yield call(updateFileStatusApi, file_id, data);
        const resp = response && response.data;

        yield put(updateFileStatusSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to update file details";
        yield put(updateFileStatusFailed(message));
    }
}

export function* reSendFile(actions) {
    try {
        const { file_id, data, uploadProgress } = actions.payload;

        const response = yield call(
            reUploadFileApi,
            file_id,
            data,
            uploadProgress,
        );
        const resp = response && response.data;

        yield put(reUploadFileSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to re-upload file";
        yield put(reUploadFileFailed(message));
    }
}

export function* sendFileTemplate(actions) {
    try {
        const { id, data, uploadProgress } = actions.payload;

        const response = yield call(
            uploadFileTemplateApi,
            id,
            data,
            uploadProgress,
        );
        const resp = response && response.data;

        yield put(uploadFileTemplateSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to upload file template";
        yield put(uploadFileTemplateFailed(message));
    }
}

export function* getFileHistory(actions) {
    try {
        const { file_id } = actions.payload;

        const response = yield call(getFileHistoryApi, file_id);
        const resp = response && response.data;

        yield put(fetchFileHistorySuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to get workspace file history";
        yield put(fetchFileHistoryFailed(message));
    }
}

export function* getFileStats(actions) {
    try {
        const { workspace_id, status, searchTerm } = actions.payload;

        const response = yield call(
            getFileStatsApi,
            workspace_id,
            status,
            searchTerm,
        );
        const resp = response && response.data;

        yield put(fetchFileStatsSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to get workspace file stats";
        yield put(fetchFileStatsFailed(message));
    }
}

export function* watchDownloadTemplate() {
    yield takeLatest(downloadTemplate.type, getTemplate);
}

export function* watchuploadFile() {
    yield takeLeading(uploadFile.type, sendFile);
}

export function* watchfetchWorkspaceFiles() {
    yield takeLeading(fetchWorkspaceFiles.type, getFiles);
}

export function* watchfetchFileDetails() {
    yield takeLeading(fetchFileDetails.type, getFileDetails);
}

export function* watchUpdateFileStatus() {
    yield takeLeading(updateFileStatus.type, changeFileStatus);
}

export function* watchReUploadFile() {
    yield takeLeading(reUploadFile.type, reSendFile);
}

export function* watchUploadFileTemplate() {
    yield takeLeading(uploadFileTemplate.type, sendFileTemplate);
}

export function* watchFetchFileHistory() {
    yield takeLeading(fetchFileHistory.type, getFileHistory);
}

export function* watchFetchFileStats() {
    yield takeLeading(fetchFileStats.type, getFileStats);
}
