import { call, put, takeLeading } from "redux-saga/effects";
import { extractErrors, processResponseObject } from "../../../util/helpers";
import {
    login as loginApi,
    signup as signupApi,
    logout as logoutApi,
    fetchUser as fetchUserApi,
    updateProfile as updateProfileApi,
    fetchAnonymousUserInvitation as fetchAnonymousUserInvitationApi,
} from "./api";
import {
    loginRequest,
    loginSuccess,
    loginFailed,
    signUpRequest,
    signUpSuccess,
    signUpFailed,
    logoutRequest,
    loginRedirect,
    fetchUser,
    fetchUserSuccess,
    fetchUserFailed,
    updateProfile,
    updateProfileSuccess,
    updateProfileFailed,
    fetchAnonymousUserInvitation,
    fetchAnonymousUserInvitationSuccess,
    fetchAnonymousUserInvitationFailed,
} from "./slice";

export function* getAnonymousInvitation() {
    try {
        const response = yield call(fetchAnonymousUserInvitationApi);
        const resp = response && response.data;

        yield put(fetchAnonymousUserInvitationSuccess(resp));
    } catch (e) {
        const message =
            e?.response?.data?.detail || "Failed to fetch invitation token";
        yield put(fetchAnonymousUserInvitationFailed(message));
    }
}

export function* getUser() {
    try {
        const response = yield call(fetchUserApi);
        const resp = response && response.data;

        yield put(fetchUserSuccess(resp));
    } catch (e) {
        const message = e?.response?.data?.detail || "Failed to fetch user";
        yield put(fetchUserFailed(message));
    }
}

export function* signin(actions) {
    try {
        yield call(loginApi, actions?.payload);

        yield put(loginSuccess());
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to signin user";
        yield put(loginFailed(message));
    }
}

export function* signup(actions) {
    try {
        const response = yield call(signupApi, actions?.payload);
        const resp = response && response.data;

        yield put(loginRedirect(processResponseObject(resp)));
        yield put(signUpSuccess());
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to signup user";
        yield put(signUpFailed(message));
    }
}

export function* editProfile(actions) {
    try {
        yield call(updateProfileApi, actions?.payload);

        yield put(updateProfileSuccess());
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to complete profile";
        yield put(updateProfileFailed(message));
    }
}

export function* signout() {
    try {
        yield call(logoutApi);
        window.location.replace("/");
    } catch (e) {
        window.location.replace("/");
    }
}

export function* watchLogin() {
    yield takeLeading(loginRequest.type, signin);
}

export function* watchSignUp() {
    yield takeLeading(signUpRequest.type, signup);
}

export function* watchUpdateProfile() {
    yield takeLeading(updateProfile.type, editProfile);
}

export function* watchLogout() {
    yield takeLeading(logoutRequest.type, signout);
}

export function* watchFetchUser() {
    yield takeLeading(fetchUser.type, getUser);
}

export function* watchFetchAnonymousUserInvitation() {
    yield takeLeading(
        fetchAnonymousUserInvitation.type,
        getAnonymousInvitation,
    );
}
