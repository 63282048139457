import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: "#fff",
            paper: "#fff",
        },
        text: {
            primary: "#173A5E",
            secondary: "#46505A",
        },
        action: {
            active: "#001E3C",
        },
        secondary: {
            main: "#000000",
        },
        table: {
            backgroundColor: {
                default: "#fff",
            },
            textColor: {
                default: "#000000",
            },
        },
    },
    typography: {
        menu1: {
            fontSize: 18,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        menu2: {
            fontSize: 12,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        menu3: {
            fontSize: 15,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        title1: {
            fontSize: 20,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        title2: {
            fontSize: 18,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        title3: {
            fontSize: 16,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        title4: {
            fontSize: 14,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        title6: {
            fontSize: 12,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        title7: {
            fontSize: 10,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        title8: {
            fontSize: 8,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 500,
        },
        detailsLabel: { fontSize: 20, fontWeight: 500 },
        detailsValue: { fontSize: 14, fontWeight: 400 },
    },
    breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
        "custom-lg": 1500,
    },
    spacing: 0,
    buttongroup: {
        background: "#c34814 !important",
        padding: "7px 16px",
        "&:hover": {
            background: "#c34814 !important",
        },
    },
});
