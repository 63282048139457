import {
    call,
    put,
    takeEvery,
    takeLatest,
    takeLeading,
} from "redux-saga/effects";
import { processResponseObject } from "../../../util/helpers";
import {
    addWorkspace as addWorkspaceApi,
    fetchWorkspaces as fetchWorkspacesApi,
    updateWorkspace as updateWorkspaceApi,
    getWorkspace as getWorkspaceApi,
    deleteWorkspace as deleteWorkspaceApi,
    fetchWorkspacePermissions as fetchWorkspacePermissionsApi,
} from "./api";
import {
    addWorkspace,
    addWorkspaceSuccess,
    addWorkspaceFailed,
    updateWorkspace,
    updateWorkspaceSuccess,
    updateWorkspaceFailed,
    fetchWorkspacePermissions,
    fetchWorkspacePermissionsSuccess,
    fetchWorkspacePermissionsFailed,
    fetchWorkspaces,
    fetchWorkspacesSuccess,
    fetchWorkspacesFailed,
    getWorkspace,
    getWorkspaceSuccess,
    getWorkspaceFailed,
    deleteWorkspace,
    deleteWorkspaceSuccess,
    deleteWorkspaceFailed,
} from "./slice";

export function* createWorkspace(actions) {
    try {
        const response = yield call(addWorkspaceApi, {
            ...actions.payload,
        });
        const resp = response && response.data;
        yield put(addWorkspaceSuccess(resp));
    } catch (e) {
        const message =
            processResponseObject(e?.response?.data) ||
            "Failed to add workspace";
        yield put(addWorkspaceFailed(message));
    }
}

export function* editWorkspace(actions) {
    try {
        const { slug } = actions.payload;

        const response = yield call(updateWorkspaceApi, slug, {
            ...actions?.payload,
        });
        const resp = response && response.data;

        yield put(updateWorkspaceSuccess(resp));
    } catch (e) {
        const message =
            processResponseObject(e?.response?.data) ||
            "Failed to update workspace";
        yield put(updateWorkspaceFailed(message));
    }
}

export function* logintoWorkspace(actions) {
    try {
        const { id } = actions.payload;
        const response = yield call(fetchWorkspacePermissionsApi, id);
        const resp = response && response.data;
        yield put(
            fetchWorkspacePermissionsSuccess({
                permissions: resp?.perms,
                role: resp?.role,
            }),
        );
    } catch (error) {
        const message =
            processResponseObject(error?.response?.data) ||
            "Failed to fetch workspace permissions";
        yield put(fetchWorkspacePermissionsFailed(message));
    }
}

export function* getWorkspaces(actions) {
    try {
        const response = yield call(fetchWorkspacesApi, {
            ...actions.payload,
        });
        const resp = response && response.data;
        yield put(fetchWorkspacesSuccess(resp));
    } catch (error) {
        const message =
            processResponseObject(error?.response?.data) ||
            "Failed to fetch workspaces";
        yield put(fetchWorkspacesFailed(message));
    }
}

export function* getSingleWorkspace(actions) {
    try {
        const response = yield call(getWorkspaceApi, actions.payload);
        const resp = response && response.data;
        yield put(getWorkspaceSuccess(resp));
    } catch (error) {
        const message =
            processResponseObject(error?.response?.data) ||
            "Failed to fetch workspace";
        yield put(getWorkspaceFailed(message));
    }
}

export function* removeWorkspace(actions) {
    try {
        const { id } = actions.payload;
        const response = yield call(deleteWorkspaceApi, id);
        const resp = response && response.data;
        yield put(deleteWorkspaceSuccess(resp));
    } catch (error) {
        const message =
            processResponseObject(error?.response?.data) ||
            "Failed to remove workspace ";
        yield put(deleteWorkspaceFailed(message));
    }
}

export function* watchAddWorkspace() {
    yield takeLeading(addWorkspace.type, createWorkspace);
}

export function* watchUpdateWorkspace() {
    yield takeLeading(updateWorkspace.type, editWorkspace);
}

export function* watchfetchWorkspacePermissions() {
    yield takeLeading(fetchWorkspacePermissions.type, logintoWorkspace);
}

export function* watchFetchWorkspaces() {
    yield takeLatest(fetchWorkspaces.type, getWorkspaces);
}

export function* watchGetSingleWorkspace() {
    yield takeLatest(getWorkspace.type, getSingleWorkspace);
}

export function* watchDeleteWorkspace() {
    yield takeEvery(deleteWorkspace.type, removeWorkspace);
}
