import { createSlice } from "@reduxjs/toolkit";

const intital_statistics_state = [
    { awaiting_review: 0 },
    { pending: 0 },
    { approved: 0 },
    { declined: 0 },
];

const initialState = {
    downloadTemplateState: { template: null, isloading: "", errors: null },
    uploadFileState: { data: null, isloading: "", errors: null },
    uploadFileTemplateState: { data: null, isloading: "", errors: null },
    fetchWorkspaceFilesState: { files: [], isloading: "", errors: null },
    fetchFileDetailsState: { file: null, isloading: "", errors: null },
    updateFileStatusState: { file: null, isloading: "", errors: null },
    reUploadFileState: { data: null, isloading: "", errors: null },
    fetchFileHistoryState: { history: [], isloading: "", errors: null },
    fetchFileStatsState: {
        statistics: intital_statistics_state,
        isloading: "",
        errors: null,
    },
};

export const filesSlice = createSlice({
    name: "files",
    initialState,
    reducers: {
        downloadTemplate: (state) => {
            state.downloadTemplateState.isloading = "processing";
            state.downloadTemplateState.errors = null;
        },
        downloadTemplateSuccess: (state) => {
            state.downloadTemplateState.isloading = "success";
        },
        downloadTemplateFailed: (state, action) => {
            state.downloadTemplateState.isloading = "failed";
            state.downloadTemplateState.errors = action?.payload;
        },
        uploadFile: (state) => {
            state.uploadFileState.isloading = "processing";
            state.uploadFileState.errors = null;
        },
        uploadFileSuccess: (state) => {
            state.uploadFileState.isloading = "success";
        },
        uploadFileFailed: (state, action) => {
            state.uploadFileState.isloading = "failed";
            state.uploadFileState.errors = action?.payload;
        },
        fetchWorkspaceFiles: (state) => {
            state.fetchWorkspaceFilesState.isloading = "processing";
            state.fetchWorkspaceFilesState.errors = null;
        },
        fetchWorkspaceFilesSuccess: (state, action) => {
            state.fetchWorkspaceFilesState.isloading = "success";
            state.fetchWorkspaceFilesState.files = action?.payload;
        },
        fetchWorkspaceFilesFailed: (state, action) => {
            state.fetchWorkspaceFilesState.isloading = "failed";
            state.fetchWorkspaceFilesState.errors = action?.payload;
        },
        fetchFileDetails: (state) => {
            state.fetchFileDetailsState.isloading = "processing";
            state.fetchFileDetailsState.errors = null;
        },
        fetchFileDetailsSuccess: (state, action) => {
            state.fetchFileDetailsState.isloading = "success";
            state.fetchFileDetailsState.file = action?.payload;
        },
        fetchFileDetailsFailed: (state, action) => {
            state.fetchFileDetailsState.isloading = "failed";
            state.fetchFileDetailsState.errors = action?.payload;
        },
        updateFileStatus: (state) => {
            state.updateFileStatusState.isloading = "processing";
            state.updateFileStatusState.errors = null;
        },
        updateFileStatusSuccess: (state, action) => {
            state.updateFileStatusState.isloading = "success";
            state.updateFileStatusState.file = action?.payload;
        },
        updateFileStatusFailed: (state, action) => {
            state.updateFileStatusState.isloading = "failed";
            state.updateFileStatusState.errors = action?.payload;
        },
        reUploadFile: (state) => {
            state.reUploadFileState.isloading = "processing";
            state.reUploadFileState.errors = null;
        },
        reUploadFileSuccess: (state, action) => {
            state.reUploadFileState.isloading = "success";
            state.reUploadFileState.file = action?.payload;
        },
        reUploadFileFailed: (state, action) => {
            state.reUploadFileState.isloading = "failed";
            state.reUploadFileState.errors = action?.payload;
        },
        uploadFileTemplate: (state) => {
            state.uploadFileTemplateState.isloading = "processing";
            state.uploadFileTemplateState.errors = null;
        },
        uploadFileTemplateSuccess: (state) => {
            state.uploadFileTemplateState.isloading = "success";
        },
        uploadFileTemplateFailed: (state, action) => {
            state.uploadFileTemplateState.isloading = "failed";
            state.uploadFileTemplateState.errors = action?.payload;
        },
        fetchFileHistory: (state) => {
            state.fetchFileHistoryState.isloading = "processing";
            state.fetchFileHistoryState.errors = null;
        },
        fetchFileHistorySuccess: (state, action) => {
            state.fetchFileHistoryState.isloading = "success";
            state.fetchFileHistoryState.history = action?.payload;
        },
        fetchFileHistoryFailed: (state, action) => {
            state.fetchFileHistoryState.isloading = "failed";
            state.fetchFileHistoryState.errors = action?.payload;
        },
        fetchFileStats: (state) => {
            state.fetchFileStatsState.isloading = "processing";
            state.fetchFileStatsState.errors = null;
        },
        fetchFileStatsSuccess: (state, action) => {
            state.fetchFileStatsState.isloading = "success";
            state.fetchFileStatsState.statistics = action?.payload;
        },
        fetchFileStatsFailed: (state, action) => {
            state.fetchFileStatsState.isloading = "failed";
            state.fetchFileStatsState.errors = action?.payload;
        },
        resetFetchWorkspaceFiles: (state) => {
            state.fetchWorkspaceFilesState.isloading = "success";
            state.fetchWorkspaceFilesState.files = [];
            state.fetchWorkspaceFilesState.errors = "";
        },
        resetFetchFileDetails: (state) => {
            state.fetchFileDetailsState.isloading = "";
            state.fetchFileDetailsState.errors = "";
            state.fetchFileDetailsState.file = null;
        },
        resetUpdateFileStatus: (state) => {
            state.updateFileStatusState.isloading = "";
            state.updateFileStatusState.errors = "";
            state.updateFileStatusState.file = null;
        },
        resetReuploadFile: (state) => {
            state.reUploadFileState.isloading = "";
            state.reUploadFileState.errors = "";
            state.reUploadFileState.data = null;
        },
        resetUploadFile: (state) => {
            state.uploadFileState.isloading = "";
            state.uploadFileState.errors = "";
            state.uploadFileState.data = null;
        },
        resetUploadFileTemplate: (state) => {
            state.uploadFileTemplateState.isloading = "";
            state.uploadFileTemplateState.errors = "";
            state.uploadFileTemplateState.data = null;
        },
        resetDownloadTemplate: (state) => {
            state.downloadTemplateState.isloading = "";
            state.downloadTemplateState.errors = "";
            state.downloadTemplateState.template = null;
        },
        resetFetchFileHistory: (state) => {
            state.fetchFileHistoryState.isloading = "";
            state.fetchFileHistoryState.errors = "";
            state.fetchFileHistoryState.history = [];
        },
        resetFetchFileStats: (state) => {
            state.fetchFileStatsState.isloading = "";
            state.fetchFileStatsState.errors = "";
            state.fetchFileStatsState.statistics = intital_statistics_state;
        },
    },
});

export const {
    downloadTemplate,
    downloadTemplateSuccess,
    downloadTemplateFailed,
    uploadFile,
    uploadFileSuccess,
    uploadFileFailed,
    fetchWorkspaceFiles,
    fetchWorkspaceFilesSuccess,
    fetchWorkspaceFilesFailed,
    fetchFileDetails,
    fetchFileDetailsSuccess,
    fetchFileDetailsFailed,
    updateFileStatus,
    updateFileStatusSuccess,
    updateFileStatusFailed,
    reUploadFile,
    reUploadFileSuccess,
    reUploadFileFailed,
    uploadFileTemplate,
    uploadFileTemplateSuccess,
    uploadFileTemplateFailed,
    fetchFileHistory,
    fetchFileHistorySuccess,
    fetchFileHistoryFailed,
    fetchFileStats,
    fetchFileStatsSuccess,
    fetchFileStatsFailed,
    resetFetchFileDetails,
    resetFetchWorkspaceFiles,
    resetUpdateFileStatus,
    resetReuploadFile,
    resetUploadFile,
    resetUploadFileTemplate,
    resetDownloadTemplate,
    resetFetchFileHistory,
    resetFetchFileStats,
} = filesSlice.actions;

export default filesSlice.reducer;
