import { all } from "redux-saga/effects";
import {
    watchFetchAnonymousUserInvitation,
    watchFetchUser,
    watchLogin,
    watchLogout,
    watchSignUp,
    watchUpdateProfile,
} from "./services/common/auth/sagas";
import {
    watchFetchInvitations,
    watchReplyInvitation,
    watchSendInvitation,
    watchUpdateInvitation,
} from "./services/common/invitations/sagas";
import {
    watchFetchMembers,
    watchUpdateMembership,
    watchUpdateUserRole,
} from "./services/common/membership/sagas";
import {
    watchDownloadTemplate,
    watchFetchFileHistory,
    watchFetchFileStats,
    watchReUploadFile,
    watchUpdateFileStatus,
    watchUploadFileTemplate,
    watchfetchFileDetails,
    watchfetchWorkspaceFiles,
    watchuploadFile,
} from "./services/common/files/sagas";
import {
    watchAddWorkspace,
    watchDeleteWorkspace,
    watchfetchWorkspacePermissions,
    watchFetchWorkspaces,
    watchGetSingleWorkspace,
    watchUpdateWorkspace,
} from "./services/common/workspace/sagas";

export default function* rootSaga() {
    yield all([
        watchLogout(),
        watchFetchUser(),
        watchLogin(),
        watchSignUp(),
        watchFetchWorkspaces(),
        watchfetchWorkspacePermissions(),
        watchAddWorkspace(),
        watchUpdateWorkspace(),
        watchGetSingleWorkspace(),
        watchDeleteWorkspace(),
        watchFetchMembers(),
        watchUpdateMembership(),
        watchSendInvitation(),
        watchFetchInvitations(),
        watchReplyInvitation(),
        watchUpdateInvitation(),
        watchDownloadTemplate(),
        watchuploadFile(),
        watchfetchWorkspaceFiles(),
        watchfetchFileDetails(),
        watchUpdateFileStatus(),
        watchReUploadFile(),
        watchUpdateUserRole(),
        watchUploadFileTemplate(),
        watchUpdateProfile(),
        watchFetchFileHistory(),
        watchFetchFileStats(),
        watchFetchAnonymousUserInvitation(),
    ]);
}
