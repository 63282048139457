import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { processResponseObject } from "../../../util/helpers";
import {
    fetchMembers as fetchMembersApi,
    updateMembership as updateMembershipApi,
    updateUserRole as updateUserRoleApi,
} from "./api";
import {
    updateMembership,
    updateMembershipSuccess,
    updateMembershipFailed,
    fetchMembers,
    fetchMembersSuccess,
    fetchMembersFailed,
    updateUserRole,
    updateUserRoleSuccess,
    updateUserRoleFailed,
} from "./slice";

export function* editMembership(actions) {
    try {
        const { id, workspace_id, data } = actions.payload;

        const response = yield call(
            updateMembershipApi,
            id,
            workspace_id,
            data,
        );
        const resp = response && response.data;

        yield put(updateMembershipSuccess(resp));
    } catch (e) {
        const message =
            processResponseObject(e?.response?.data) ||
            "Failed to update membership";
        yield put(updateMembershipFailed(message));
    }
}

export function* editUserRole(actions) {
    try {
        const { id, workspace_id, data } = actions.payload;

        const response = yield call(updateUserRoleApi, id, workspace_id, data);
        const resp = response && response.data;

        yield put(updateUserRoleSuccess(resp));
    } catch (e) {
        const message =
            processResponseObject(e?.response?.data) ||
            "Failed to update UserRole";
        yield put(updateUserRoleFailed(message));
    }
}

export function* getMembers(actions) {
    try {
        const slug = actions?.payload;
        const response = yield call(fetchMembersApi, slug);
        const resp = response && response.data;
        yield put(fetchMembersSuccess(resp));
    } catch (error) {
        const message =
            processResponseObject(error?.response?.data) ||
            "Failed to fetch members";
        yield put(fetchMembersFailed(message));
    }
}

export function* watchUpdateMembership() {
    yield takeLeading(updateMembership.type, editMembership);
}

export function* watchUpdateUserRole() {
    yield takeLeading(updateUserRole.type, editUserRole);
}

export function* watchFetchMembers() {
    yield takeLatest(fetchMembers.type, getMembers);
}
